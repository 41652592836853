import { DateNavigator, DateNavigatorBaseProps } from 'react/sqior-ui';
import { EntityHeader } from '@sqior/js/entity';
import { FactoryProps } from '@sqior/react/factory';

export type DateNavigatorType = EntityHeader & DateNavigatorBaseProps;
export type DateNavigatorFactoryProps = FactoryProps<DateNavigatorType>;

export function ORWorkflowDateNavigator({ data }: DateNavigatorFactoryProps) {
  const prevDisabled = false;
  const nextDisabled = false;

  const onPrev = () => {
    // TODO: Implement onPrev
    console.error('onPrev not implemented');
  };

  const onNext = () => {
    // TODO: Implement onNext
    console.error('onNext not implemented');
  };

  return (
    <DateNavigator
      onPrev={onPrev}
      onNext={onNext}
      prevDisabled={prevDisabled}
      nextDisabled={nextDisabled}
      {...data}
    />
  );
}

export default ORWorkflowDateNavigator;
