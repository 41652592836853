import { ClockTimestamp } from '@sqior/js/data';
import { EntityHeader } from '@sqior/js/entity';
import { ColorCodes } from '@sqior/viewmodels/visual';

export enum AnalyticViewModelTypes {
  TimeSeriesCardVM = 'TimeSeriesCardVM',
  StackedTimeSeriesCardVM = 'StackedTimeSeriesCardVM',
  SeriesCardVM = 'SeriesCardVM',
  PieChartCardVM = 'PieChartCardVM',
  BarChartCardVM = 'BarChartCardVM',
  StackedBarChartCardVM = 'StackedBarChartCardVM',
  TimeSeriesBarChartCardVM = 'TimeSeriesBarChartCardVM',
  TimeSeriesStackedBarChartCardVM = 'TimeSeriesStackedBarChartCardVM',
  HistogramCardVM = 'HistogramCardVM',
  StatisticComparisonItemVM = 'StatisticComparisonItemVM',
  StatisticComparisonVM = 'StatisticComparisonVM',
}

export type TimeSeriesChartVM = EntityHeader & {
  title: string;
  unit: string;
  currentValue?: number;
  avgValue?: number;
  trend?: string;

  // GraphChart
  chart: TimeSeriesChart;
};

export type StackedTimeSeriesChartVM = EntityHeader & {
  title: string;
  unit: string;
  currentValue?: number;
  avgValue?: number;
  trend?: string;

  // GraphChart
  chart: StackedTimeSeriesChart;
};

export type TimeSeriesEntry = {
  timestamp: ClockTimestamp;
  values: (number | 'noValue')[];
};

export type ChartValueLabel = { prefix?: string; suffix?: string; data: string[] };
export type HelpLine = { name: string; value: number; color?: ColorCodes };

type TimeSeriesChart = {
  timeSeries: ClockTimestamp[];
  graph: {
    axis: 'primary' | 'secondary';
    name: string;
    color?: ColorCodes;
    values: (number | 'noValue')[];
    valueLabels?: ChartValueLabel[];
  }[];
  helpLines?: HelpLine[];
};
type StackedTimeSeriesChart = {
  timeSeries: ClockTimestamp[];
  graph: { name: string; color?: ColorCodes; values: (number | 'noValue')[] }[];
  helpLines?: HelpLine[];
};

/** PieChart */
export type PieChartCardVM = EntityHeader & {
  title: string;

  // GraphChart
  chart: PieChart;
};

export type ChartValue = { name: string; value: number; valueLabel?: string; color?: ColorCodes };
export type PieChart = {
  data: ChartValue[];
  unit: string;
  alternativeText?: string; // If set, display alternativeText instead of chart area
};

export type HistogramCardVM = EntityHeader & {
  title: string;

  // GraphChart
  chart: HistogramChart;
};

export type HistogramChart = BasicBarChart;

export type BasicBarChart = {
  data: ChartValue[];
  unit?: string;
  color?: ColorCodes;
  helpLines?: HelpLine[];
};

export enum BarChartOrientation {
  BarsToRight,
  BarsToUp,
}
export type BarChart = BasicBarChart & {
  orientation?: BarChartOrientation;
};

export type BarChartCardVM = EntityHeader & {
  title: string;
  chart: BarChart;
};
