import styles from './typography.module.css';
import { TypographyType } from './typography.types';
import { HTMLAttributes, JSX } from 'react';

export interface TypographyProps extends HTMLAttributes<HTMLElement> {
  children: JSX.Element | string;
  type?: TypographyType;
  primary?: boolean;
  secondary?: boolean;
  className?: string;
  monoSpace?: boolean;
}

const DEFAULT_COMPONENT: TypographyType = 'p';

export function Typography({
  children,
  type = DEFAULT_COMPONENT,
  primary,
  secondary,
  monoSpace,
  className,
  ...rest
}: TypographyProps) {
  const Component = type;
  const primaryClass = primary ? styles['primary'] : '';
  const secondaryClass = secondary ? styles['secondary'] : '';

  return (
    <Component
      className={`${styles[type]} ${primaryClass} ${secondaryClass} ${className}`}
      {...rest}
    >
      {children}
    </Component>
  );
}

export default Typography;
