import { PluginContext } from '@sqior/js/plugin';
import { StateOverlayContext } from '@sqior/js/state-operation';
import { FactoryContext } from '@sqior/react/factory';
import { UIVisualPlugin } from '@sqior/react/uivisual';
import {
  AnalyticViewModelTypes,
  GraphEdgeDataTypeStatistic,
  OccupancyAnalyticsDashboard,
  ORWorkflowAnalyticsDashboard,
} from '@sqior/viewmodels/analytics-dashboard';
import { lazy } from 'react';

const BmAnalyticsFC = lazy(() => import('./bm-analytics/bm-analytics'));
const OpAnalyticsFC = lazy(() => import('./op-analytics/op-analytics'));
const FlowChartLabel = lazy(() => import('./flow-chart-label/flow-chart-label'));
const TimeSeriesChartFactory = lazy(
  () => import('./time-series-chart-factory/time-series-chart-factory')
);
const PieChartFactory = lazy(() => import('./pie-chart-factory/pie-chart-factory'));
const StackedChartFactory = lazy(() => import('./stacked-chart-factory/stacked-chart-factory'));
const StatisticsComparisonFactory = lazy(
  () => import('./statistics-comparison-factory/statistics-comparison-factory')
);
const HistogramChartFactory = lazy(
  () => import('./histogram-chart-factory/histogram-chart-factory')
);

const StackedBarChartFactory = lazy(
  () => import('./stacked-bar-chart-factory/stacked-bar-chart-factory')
);

const SeriesChartFactory = lazy(() => import('./series-chart-factory/series-chart-factory'));
const BarChartFactory = lazy(() => import('./bar-chart-factory/bar-chart-factory'));

/** Plugin for react components related to treatment path handling */

export function UIAnalyticsPlugin(context: PluginContext & FactoryContext & StateOverlayContext) {
  context.plugins.require(UIVisualPlugin, context);
  /* Factory components */
  context.factory.add(OccupancyAnalyticsDashboard, BmAnalyticsFC);
  context.factory.add(ORWorkflowAnalyticsDashboard, OpAnalyticsFC);
  context.factory.add(GraphEdgeDataTypeStatistic, FlowChartLabel);
  context.factory.add(AnalyticViewModelTypes.TimeSeriesCardVM, TimeSeriesChartFactory);
  context.factory.add(AnalyticViewModelTypes.SeriesCardVM, SeriesChartFactory);
  context.factory.add(AnalyticViewModelTypes.PieChartCardVM, PieChartFactory);
  context.factory.add(AnalyticViewModelTypes.StackedTimeSeriesCardVM, StackedChartFactory);
  context.factory.add(AnalyticViewModelTypes.HistogramCardVM, HistogramChartFactory);
  context.factory.add(AnalyticViewModelTypes.StatisticComparisonVM, StatisticsComparisonFactory);
  context.factory.add(AnalyticViewModelTypes.BarChartCardVM, BarChartFactory);
  context.factory.add(
    AnalyticViewModelTypes.TimeSeriesStackedBarChartCardVM,
    StackedBarChartFactory
  );
}
