import { Entity, EntityHeader } from '@sqior/js/entity';
import { OperationSpec, OperationType } from '@sqior/js/operation';
import { joinPath } from '@sqior/js/url';
import { SelectTimeRange } from '@sqior/viewmodels/input';
import { AnalyticsDashboardStatePath } from './paths';
import { MetricCardStats, TableData } from './types';
import {
  WorkflowAnalyticsDashboardOpSubPaths,
  WorkflowAnalyticsDashboardStateOperationData,
} from './workflow-analytics-dashboard';

export const ORWorkflowAnalyticsDashboard = 'ORWorkflowAnalyticsDashboard';
export const ORWorkflowAnalyticsDashboardOpPath = 'ORWorkflowAnalyticsDashboard';

export type ORWorkflowAnalyticsDashboardVM = EntityHeader & {
  // new stuff to add
};

export function ORWorkflowAnalyticsDashboardOperation(
  data: WorkflowAnalyticsDashboardStateOperationData
): OperationSpec<WorkflowAnalyticsDashboardStateOperationData> {
  return { type: OperationType.Add, path: AnalyticsDashboardStatePath, data };
}

export function ORWorkflowAnalyticsDashboardSelectEdge(id: string[]): OperationSpec<string[]> {
  return {
    type: OperationType.Add,
    path: joinPath(AnalyticsDashboardStatePath, WorkflowAnalyticsDashboardOpSubPaths.SelectEdge),
    data: id,
  };
}

export function ORWorkflowAnalyticsDashboardSelectNode(id: string[]): OperationSpec<string[]> {
  return {
    type: OperationType.Add,
    path: joinPath(AnalyticsDashboardStatePath, WorkflowAnalyticsDashboardOpSubPaths.SelectNode),
    data: id,
  };
}

export function ORWorkflowAnalyticsDashboardTimeRange(
  select: string | { start?: number; end?: number }
) {
  return SelectTimeRange(
    joinPath(AnalyticsDashboardStatePath, WorkflowAnalyticsDashboardOpSubPaths.SelectTimeRange),
    select
  );
}

export function ORWorkflowAnalyticsDashboardSelectSpecialty(id: string): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(
      AnalyticsDashboardStatePath,
      WorkflowAnalyticsDashboardOpSubPaths.SelectSpecialty
    ),
    data: id,
  };
}

export function ORWorkflowAnalyticsDashboardSelectFilter(id: string): OperationSpec<string> {
  return {
    type: OperationType.Add,
    path: joinPath(AnalyticsDashboardStatePath, WorkflowAnalyticsDashboardOpSubPaths.SelectFilter),
    data: id,
  };
}

/** Performance state */

export const ORWorkflowAnalyticsPerformanceSubPath = 'perf';

export type ORWorkflowAnalyticsPerformanceVM = {
  total: MetricCardStats;

  mainIncidentChart?: Entity;
  incidentCharts: Entity[];

  incisionSuture: MetricCardStats;
  incisionSutureTable: TableData;

  //anesthesia: MetricCardStats;
  anesthesiaTable: TableData;
};
