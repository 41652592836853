import { BlockerListView, BlockerListViewProps } from 'react/sqior-ui';
import { EntityHeader } from '@sqior/js/entity';
import { FactoryProps } from '@sqior/react/factory';

export type BlockerListViewType = EntityHeader & BlockerListViewProps;
export type BlockerListViewFactoryProps = FactoryProps<BlockerListViewType>;

export function ORWorkflowBlockerList({ data }: BlockerListViewFactoryProps) {
  return <BlockerListView {...data} />;
}

export default ORWorkflowBlockerList;
